import { BU_NAME } from 'newHirePortalRedesign/newHire/util/constants';
import { CoreMemberType } from 'newHirePortalRedesign/newHire/features/banner/store/selectors/selectors';
import brianSignature from 'newHirePortalRedesign/newHire/features/banner/assets/brianSignature.png';
import { ParagraphSmall } from 'baseui/typography';
import React from 'react';

export interface CoreMemberMessage {
  name: string;
  id: string;
  message:  React.ReactNode | string;
  buName: string;
  signatureImgSrc: string;
}

export const CORE_MEM_MSG: CoreMemberMessage[] = [
  {
    name: 'Steve Lee',
    id: '009436',
    message: 'Welcome to Block!\nBest,',
    buName: BU_NAME.BLOCK,
    signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
  },
  {
    name: 'Brian Grassadonia',
    id: '000134',
    buName: BU_NAME.CASH,
    signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
    message: (
      (
        <ParagraphSmall>
        Welcome to Cash. I’m thrilled that you’ve decided to join our team. No
        matter what your role is, our mission is the same: redefine the world’s
        relationship with money by making it more relatable, instantly available
        and universally accessible. We do this when we
        <i>
          {' '}
          Insist on Focus, Compete on Creativity, Feel it, Climb Walls and Fight
          Complacency
        </i>
        . That’s a lot of words, but I think you’ll find that these principles
        come naturally to you. I’m looking forward to seeing the positive impact
        you’ll make and the incredible things we’ll create together.
        <ParagraphSmall>Best,</ParagraphSmall>
      </ParagraphSmall>
      )
    ),
  },
  {
    name: 'Amrita Ahuja',
    id: '008623',
    buName: BU_NAME.BLOCK,
    signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
    message: (
      (
        <ParagraphSmall>
          On behalf of the Guidance organization, welcome to Block!
          We are so excited to have you join our team. Our mission is to enable bold growth at scale, through functional excellence, operational partnership and strategic thoughtfulness.
          We do this by putting people first, striving for agility, enabling the organization to take principled risks, and driving new growth opportunities.
          We are excited to have you join the team to help us push this mission forward!
          I look forward to working with you and am around if there is anything I can help you with.
        <ParagraphSmall>Best,</ParagraphSmall>
      </ParagraphSmall>
      )
    ),
  },
];

export const getCoreMemberMsg = (member, buName) => {
  const byId = CORE_MEM_MSG.find(
    (msg) => msg.id === member.employee_id && buName === msg.buName
  );
  if (byId) return byId;

  const fullName = `${member.first_name} ${member.last_name}`;
  return CORE_MEM_MSG.find(
    (msg) =>
      fullName.toLowerCase() === msg.name.toLowerCase() && buName === msg.buName
  );
};
