import React from 'react';
import { SIZE } from 'baseui/button';
import { styled, useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import { useDispatch, useSelector } from 'react-redux';
import shippingFormFields, {
  WFH_KIT_ID
} from 'newHirePortal/newHire/containers/YourInfo/Shipping/formFields';
import { updateShippingInput } from 'newHirePortal/newHire/store/shippingAddress/slice';
import FormField from '../../../../components/FormField';
import { THEME_NAME } from '../../../../util/enums';
import HomeIcon from '../../../../../../components/icons/ShippingAddress';
import slime from '../../../../../../assets/images/slime.png';
import pyramid from '../../../../../../assets/images/pyramid.png';
import CashImgWrap from '../../../../components/CashImgWrap';
import HardwareTitle from '../HardwareTitle';

const FormWrap = styled('div', () => ({
  position: 'relative'
}));

const ShippingWrapper = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.mono300}`,
  ':hover': { border: `2px solid ${$theme.colors.backgroundAccent}` },
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px'
}));

const ShippingContent = styled('div', () => ({
  marginLeft: '60px'
}));

const ShippingForm = () => {
  const dispatch = useDispatch();
  const [, theme] = useStyletron();
  const shippingData = useSelector(
    (state) => state.shippingAddress.shippingInput
  );
  const isCashTheme = theme.name === THEME_NAME.CASH;

  const handleChange = (name, value) => {
    dispatch(updateShippingInput({ [name]: value }));
  };

  /* the wfh-kit radio button optin is filtered out as the
  Hardware sections are offering all these devices in the above section */
  const fileteredFormFields = shippingFormFields.filter(
    (field) => field.id !== WFH_KIT_ID
  );

  return (
    <ShippingWrapper>
      <HardwareTitle
        icon={<HomeIcon size={30} />}
        deviceName='Shipping Address'
        infoText={[
          'Please provide an address where the selected hardware and accessories should be shipped to.',
          'This may be different from your home address.'
        ]}
      />
      <ShippingContent>
        <ParagraphSmall marginTop='-30px' paddingBottom='20px' marginLeft='2px'>
          Please note that information is not editable after submission.
        </ParagraphSmall>
        {isCashTheme ? (
          <FormWrap>
            <CashImgWrap
              position='absolute'
              top='450px'
              left='-150px'
              src={pyramid}
              alt='pyramid'
              zIndex='-1'
            />
            <CashImgWrap
              position='absolute'
              right='-50px'
              top='80px'
              src={slime}
              alt='slime'
            />
          </FormWrap>
        ) : (
          ' '
        )}
        {fileteredFormFields.map((field) => (
          <FormField
            key={field.id}
            value={shippingData[field.dataFieldName]}
            {...field}
            size={SIZE.large}
            updateFormFunc={handleChange}
            testId={field.dataFieldName}
          />
        ))}
      </ShippingContent>
    </ShippingWrapper>
  );
};

export default ShippingForm;
